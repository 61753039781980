<template>
  <vLoading :active="isLoading"></vLoading>
  <div class="flex flex-col">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full text-center">
            <thead
              class="border-b bg-neutral-50 font-medium dark:border-neutral-500 dark:text-neutral-800"
            >
              <tr>
                <th scope="col" width="5%" class="px-6 py-4">Type</th>
                <th scope="col" class="px-6 py-4">Url</th>
                <th scope class="text-right" width="10%">
                  <button
                    v-if="editColumn !== null"
                    type="button"
                    class="inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-red-700"
                    @click="clearEdit"
                  >
                    Cancel Edit
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="border-b dark:border-neutral-500"
                v-for="(item, index) in appUrl"
                :key="item.Type"
              >
                <td width="5%" class="px-6 py-4">{{ item.Type }}</td>
                <td class="px-6 py-4">
                  <span v-show="editColumn === null || editColumn !== index">
                    {{ item.Url }}
                  </span>
                  <input
                    v-model="editField.Url"
                    @keydown.esc="clearEdit"
                    type="text"
                    ref="Url"
                    v-show="editColumn === index"
                    class="block min-h-[auto] w-full text-center rounded ring ring-red-300 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none"
                  />
                </td>
                <td width="5%">
                  <button
                    v-if="editColumn === index"
                    type="button"
                    class="inline-block rounded bg-green-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-green-700"
                    @click="updateField"
                  >
                    update
                  </button>
                  <button
                    v-else
                    type="button"
                    class="inline-block rounded bg-blue-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-blue-700"
                    @click="showField(index, item)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const editField = ref({
  Url: '',
});
const editColumn = ref(null);
const Url = ref(null);
const appUrl = computed(() => store.getters['appUrl']);
const isLoading = computed(() => store.getters['isLoading']);
const showField = (index, data) => {
  editField.value = { ...data };
  editColumn.value = index;
};
const clearEdit = () => {
  editField.value = {
    Url: '',
  };
  editColumn.value = null;
};
const updateField = async () => {
  try {
    const result = await store.dispatch('updateAppUrl', editField.value);
    if (result === null) {
    }
  } catch (error) {
    alert(error);
  } finally {
    clearEdit();
  }
};
onMounted(() => {
  store.dispatch('getAppUrl');
});
</script>
